import { useHistory } from "react-router-dom"

const Price = () => {
  const history = useHistory();
  const gotoContact = (e) => {
    e.preventDefault();
    history.push('/lien-he')
  }
  return (
    <div className='priceWrap'>
      <div className='container priceContainer'>
        <div className='priceItem'>
          <div className='priceNumber'>
            60.000.000
            <span>vnđ</span>
          </div>
          <h3>Gói Demo</h3>
          <p>Hỗ trợ nhà đầu tư thử nghiệm đánh giá tính hiệu quả</p>
          <ul>
            <li>1 Kênh Youtube</li>
            <li>Lợi nhuận chia sẻ 25%</li>
            <li>Hỗ trợ 24/7</li>
            <li>Nội dung đăng tải thông thường.</li>
            <li className='inactive'>Không hỗ trợ kênh youtube riêng</li>
            <li className='inactive'>Không hỗ trợ dashboard riêng.</li>
          </ul>
          <a className='button black' onClick={(e) => gotoContact(e)} href=''>Liên hệ</a>
        </div> {/* End Item */}
        <div className='priceItem' style={{ background: '#FFE4E4' }}>
          <div className='priceNumber'>
            600. 000.000
            <span>vnđ</span>
          </div>
          <h3>Gói Basic </h3>
          <p>Hỗ trợ nhà đầu tư đáp ứng nhu cầu cơ bản</p>
          <ul>
            <li>10 Kênh Youtube</li>
            <li>Lợi nhuận chia sẻ 30%</li>
            <li>Hỗ trợ 24/7</li>
            <li>Nội dung đăng tải thông cao.</li>
            <li>Hỗ trợ phát triển 1 kênh youtube riêng (tùy chọn)</li>
            <li>Hỗ trợ dashboard riêng.</li>
          </ul>
          <a className='button black' onClick={(e) => gotoContact(e)} href=''>Đăng ký</a>
        </div> {/* End Item */}
        <div className='priceItem pro' style={{ background: '#C91212' }}>
          <div className='priceNumber'>
            1.200.000.000
            <span>vnđ</span>
          </div>
          <h3>Gói Diamond</h3>
          <p>Là gói đầu tư được nhiều người tin tưởng lựa chọn</p>
          <ul>
            <li>20 Kênh Youtube</li>
            <li>Lợi nhuận chia sẻ 40%</li>
            <li>Hỗ trợ 24/7</li>
            <li>Hỗ trợ đội ngũ SEO chất lượng cao từ chuyên gia</li>
            <li>Nội dung đăng tải VIP (xu hướng, chủ đề hot, chất lượng từ chuyên gia)</li>
            <li>Hỗ trợ phát triển 3 kênh youtube riêng (tùy chọn).</li>
            <li>Hỗ trợ dashboard</li>
          </ul>
          <div className="lastIn"></div>
          <a className='button black' onClick={(e) => gotoContact(e)}>Đăng ký</a>
        </div> {/* End Item */}
        <div className='priceItem' style={{ background: '#FFE4E4' }}>
          <div className='priceNumber'>
            Thỏa thuận
          </div>
          <h3>Gói Platinum</h3>
          <p>Gói cao nhất không giới hạn dành cho nhà đầu tư yêu cầu cao</p>
          <ul>
            <li>Không giới hạn số kênh youtube.</li>
            <li>Lợi nhuận chia sẻ lên tới tối đa 50%</li>
            <li>Hỗ trợ 24/7</li>
            <li>Hỗ trợ đội ngũ SEO chất lượng cao từ chuyên gia</li>
            <li>Nội dung đăng tải VIP (xu hướng, chủ đề hot, chất lượng từ chuyên gia)</li>
            <li>Hỗ trợ phát triển 3 kênh youtube riêng & tư vấn hoạt động marketing, bán hàng từ nền tảng</li>
            <li>Hỗ trợ dashboard</li>
          </ul>
          <a className='button black' onClick={(e) => gotoContact(e)} href=''>Liên hệ</a>
        </div> {/* End Item */}
      </div>
    </div>
  )
}

export default Price
