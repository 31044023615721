import React from 'react'
import Slider from 'react-slick'

function SampleNextArrow (props) {
  const { onClick } = props
  return (
    <div className='arrNext arr' onClick={onClick} />

  )
}

function SamplePrevArrow (props) {
  const { onClick } = props
  return (
    <div className='arrPrev arr' onClick={onClick} />
  )
}
const listTestimonial = [
  {
    name: 'Jenny Wilson',
    title: 'Vice President',
    desc: 'The best agency we’ve worked with so far. They understand our product and are able to add new features with a great focus.',
    icon: '/images/commons/avatar.png'
  },
  {
    name: 'Jenny Wilson',
    title: 'Vice President',
    desc: 'The best agency we’ve worked with so far. They understand our product and are able to add new features with a great focus.',
    icon: '/images/commons/avatar.png'
  },
  {
    name: 'Jenny Wilson',
    title: 'Vice President',
    desc: 'The best agency we’ve worked with so far. They understand our product and are able to add new features with a great focus.',
    icon: '/images/commons/avatar.png'
  }
]
const Testimonial = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  return (
    <div className='testimonialsWrap'>
      <div className='container'>
        <div className='testimonials'>
          <div className='wrapHeading'>
            <h3 className='heading'>Khách hàng nói về chúng tôi</h3>
          </div>
          <div className='testimonialList'>
            <Slider {...settings}>
              {
                                listTestimonial?.map((item, i) => (
                                  <div key={i} className='testimonialItem'>
                                    <div dangerouslySetInnerHTML={{ __html: item.desc }} className='desc' />
                                    <div className='profileInfo'>
                                      <div className='profileAvatar'>
                                        <img src={item.icon} alt={item.title} />
                                      </div>
                                      <div className='profileDetail'>
                                        <h3>{item.name}</h3>
                                        <h5>{item.title}</h5>
                                      </div>
                                    </div>
                                  </div>
                                ))
              }
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
