import HomePartner from "../components/Home/Partner/Partner"
import HomeSlider from "../components/Home/Slider/Slider"
import Services from "../components/Home/Services/Services"
import Benefit from "../components/Home/Benefit/Benefit"
import Testimonial from "../components/Home/Testimonial/Testimonial"
// import FAQs from "../components/Home/FAQs/FAQs"
import Contact from "../components/Home/Contact/Contact"

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import WhyChoseWe from "../components/Home/WhyChoseWe/WhyChoseWe"

const HomePage = () => {
    return (
        <>
            <HomeSlider />
            <HomePartner />
            <Services />
            <Benefit />
            <Testimonial />
            {/* <FAQs /> */}
            <WhyChoseWe />
            <Contact />
        </>
    )
}
export default HomePage