import FAQs from '../components/Home/FAQs/FAQs'
import Price from '../components/Prices/Price'

const PricesPage = () => {
  return (
    <>
      <div className='container text-center price'>
        <h1>Bảng giá gói dịch vụ</h1>
        <p>Bạn có thể liên hệ với chúng tôi để biết thêm thông tin chi tiết các gói dịch vụ.</p>
      </div>
      <Price />
      <FAQs />
    </>
  )
}
export default PricesPage
