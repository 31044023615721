import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'

import css from './Header.module.css'

const menus = [
  {
    link: '/',
    label: 'Trang chủ'
  },
  {
    link: '/gioi-thieu',
    label: 'Giới thiệu'
  },
  {
    link: '/bang-gia',
    label: 'Bảng giá'
  },
  {
    link: '/dich-vu',
    label: 'Dịch vụ'
  },
  {
    link: '/quy-trinh',
    label: 'Quy trình'
  },
  {
    link: '/co-so',
    label: 'Cơ sở'
  }
]
const Header = () => {
  const history = useHistory()
  const [isShowMenuMobile, setIsShowMenuMobile] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const handleShowMobileMenu = () => setIsShowMenuMobile(!isShowMenuMobile)
  const isMobile = width <= 768;
  return (
    <div className={css.headerWrap}>
      <div className='container'>
        <div className={css.headerInner}>
          <div className={'logo'} onClick={() => history.push('/')}>
            <img style={{ witdh: 150, height: 150 }} src='/images/commons/logo.svg' alt='Logo' />
          </div>
          <div className='menuIcon' onClick={handleShowMobileMenu}>
            <img src='/images/commons/menu.png' alt='menu' />
          </div>
          {(!isMobile || isShowMenuMobile === true) && <div className={css.menu}>
            {
              menus?.map(menu => (
                <NavLink to={menu.link} activeClassName="selected">
                  {menu.label}
                </NavLink>
              ))
            }

            <Link to='/dang-nhap'>
              <a className={css.btn} >Đăng nhập</a>
            </Link>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default Header
