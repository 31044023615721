import React, { useState } from 'react'

const listFAQ = [
  {
    question: 'Youtube sẽ trả tiền cho bạn với con số là bao nhiêu?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  },
  {
    question: 'Youtube sẽ trả tiền cho bạn với con số là bao nhiêu?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  },
  {
    question: 'Youtube sẽ trả tiền cho bạn với con số là bao nhiêu?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  },
  {
    question: 'Youtube sẽ trả tiền cho bạn với con số là bao nhiêu?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  },
  {
    question: 'Youtube sẽ trả tiền cho bạn với con số là bao nhiêu?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  }
]
const FAQs = () => {
  const [activeItem, setActiveItem] = useState(-1)
  return (
    <div className='testimonialsWrap faqWrap'>
      <div className='container'>
        <div className='testimonials'>
          <div className='wrapHeading'>
            <h3 className='heading'>Khách hàng nói về chúng tôi</h3>
          </div>
          <div className='testimonialList'>
            {
                            listFAQ?.map((item, i) => (
                              <div onClick={() => setActiveItem(i)} key={i} className={`testimonialItem faqItem ${activeItem === i ? 'active' : ''}`}>
                                <div className='faqNumber'>{`0${i + 1}`}</div>
                                <div className='faqContent'>
                                  <div className='question'>
                                    {item.question}
                                  </div>
                                  <div className='answer'>{item.answer}</div>
                                </div>
                              </div>
                            ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQs
