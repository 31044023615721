import React from 'react'
import Slider from 'react-slick'

const HomeSlider = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return (
    <div className='sliderHome'>
      <div className='container'>
        <Slider {...settings}>
          <div>
            <div className='sliderItem'>
              <div className='sliderContent'>
                <h3>
                                    Cơ hội đầu tư
                                    Nhượng quyền thương mại Youtube hiệu quả
                </h3>
                <p>
                                    Youtube là một trong những nền tảng mạng xã hội vượt bậc và đươc nhiều người sử dụng nhất hiện nay.
                                    Kinh doanh với Youtube theo mô hình nhượng quyền thương mại đem đến doanh thu đáng kể.
                </p>
                <a href='#' className='button black'>Đăng ký đối tác</a>
              </div>
              <div className='sliderImg'>
                <img src='/images/sliders/slider1.svg' alt='slider 1' />
              </div>
            </div>
          </div>
          <div>
            <div className='sliderItem'>
              <div className='sliderContent'>
                <h3>
                                    Cơ hội đầu tư
                                    Nhượng quyền thương mại Youtube hiệu quả
                </h3>
                <p>
                                    Youtube là một trong những nền tảng mạng xã hội vượt bậc và đươc nhiều người sử dụng nhất hiện nay.
                                    Kinh doanh với Youtube theo mô hình nhượng quyền thương mại đem đến doanh thu đáng kể.
                </p>
                <a href='#' className='button black'>Đăng ký đối tác</a>
              </div>
              <div className='sliderImg'>
                <img src='/images/sliders/slider1.svg' alt='slider 1' />
              </div>
            </div>
          </div>
          <div>
            <div className='sliderItem'>
              <div className='sliderContent'>
                <h3>
                                    Cơ hội đầu tư
                                    Nhượng quyền thương mại Youtube hiệu quả
                </h3>
                <p>
                                    Youtube là một trong những nền tảng mạng xã hội vượt bậc và đươc nhiều người sử dụng nhất hiện nay.
                                    Kinh doanh với Youtube theo mô hình nhượng quyền thương mại đem đến doanh thu đáng kể.
                </p>
                <a href='#' className='button black'>Đăng ký đối tác</a>
              </div>
              <div className='sliderImg'>
                <img src='/images/sliders/slider1.svg' alt='slider 1' />
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default HomeSlider
