import request from "../helpers/request";

export function loginFunc(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/operator/login",
    method: "post",
    data,
  });
}
