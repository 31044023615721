import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import TextField from '@mui/material/TextField';

import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

// data
// import Filter from "./Filter";
import DataTable from "../../components/Table/Table";
import DialogConfirm from "../../components/DialogConfirm/DialogConfirm";
import { depositList, depositAccept, depositReject } from "../../apis/deposit";
import { numberWithCommas, convertTimestampToDateTime, } from "../../helpers/utils";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
let timer
export default function Deposit() {
  const classes = useStyles();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true);
  // const [fullLoading, setFullLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  // const mounted = React.useRef(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectUid, setRejectUid] = useState("");
  const [note, setNote] = useState("");

  const [unit, setUnit] = useState("VNĐ")


  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const history = useHistory()

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  useEffect(() => {
    async function listData() {
      const list = await depositList(filter)
      setList(list?.data)
      setTotal(list?.total)
    }
    listData()
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleReject = async (data) => {
    // setFullLoading(true);
    await depositReject(data)
    const list = await depositList(filter)
    setList(list?.data)
    setTotal(list?.total)
    setOpenRejectDialog(false);
  }

  const handleAccept = async (data) => {
    await depositAccept(data)
    const list = await depositList(filter)
    setList(list?.data)
    setTotal(list?.total)
  }
  const columns = [
    {
      field: 'stt', headerName: 'STT', editable: false, flex: 0.1,
    },
    {
      field: 'created_at', headerName: 'created_at',
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: 'normal', }}>
            <div style={{ lineHeight: 'normal !important' }}>{convertTimestampToDateTime(params?.row?.created_at)}</div>
          </div>
        )
      }
    },
    {
      field: 'user_name', headerName: 'user',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'bank_name', headerName: 'bank_name',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'amount', headerName: 'amount',
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: 'normal' }}>
            <div style={{ lineHeight: 'normal !important' }}> {numberWithCommas(params?.row?.amount)} ({params?.row?.unit})</div>
          </div>
        )
      }
    },
    {
      field: 'amount_lucky', headerName: 'amount_lucky (LKWC)',
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: 'normal' }}>
            <div style={{ lineHeight: 'normal !important' }}> {params?.row?.amount_lucky} (LKWC)</div>
          </div>
        )
      }
    },
    {
      field: 'content', headerName: 'nội dung chuyển khoản',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'status', headerName: 'Status', width: 120,
      flex: 0.2,
      editable: false
    },
    {
      field: 'fail_reason', headerName: 'Lý do hủy',
      flex: 0.2,
      editable: false,
    },
    {
      field: 'action', headerName: 'actions', width: 120, type: 'number',
      flex: 0.2,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        if (params.row.status == "PROCESSING") {
          return (
            <div style={{ lineHeight: 'normal', }}>
              <Button
                style={{ backgroundColor: '#33CC66	', margin: 5 }}
                autoFocus onClick={async () => {
                  await handleAccept({ uid: params.row.id, note: "success" })
                }}  >
                Accept
              </Button>

              <Button
                style={{ backgroundColor: '#FF0033', margin: 2 }}
                autoFocus onClick={() => {
                  setRejectUid(params.row.id)
                  setOpenRejectDialog(true)
                }}  >
                Reject
              </Button>
            </div>
          );
        } else {
          return (<></>)
        }
      }
    }];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Lịch sử nạp tiền" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              checkboxSelection={false}
              rowHeight={90}
              columns={columns}
              data={list?.map((item, i) => ({ ...item, id: item.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>

      <Dialog
        open={openRejectDialog}
        onClose={() => { setOpenRejectDialog(false) }}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Hủy
        </DialogTitle>
        {/* <DialogContent> 
          <div style={{ float: 'right' }}>
            {!channel?.length ? <AddBoxIcon onClick={() => { }} style={{ cursor: 'pointer' }} color="secondary" /> : <></>}
          </div>
        </DialogContent> */}

        <TextField
          style={{ margin: 10 }}
          id="outlined-multiline-static"
          label="Lý do hủy "
          placeholder="Lý do hủy"
          alignContent="10"
          onChange={(event) => {
            setNote(event.target.value)
          }}
          value={note}
        />

        <DialogActions>
          <Button autoFocus onClick={() => { setOpenRejectDialog(false) }} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained" color="primary" disabled={note.length > 0 ? false : true}
            onClick={async () => {
              await handleReject({
                uid: rejectUid,
                note: note,
              });
            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
