import request from "../helpers/request";

export function depositList(params) {
    return request({
        headers: {
            "Content-Type": "application/json",
        },
        url: "/operator/deposit/list?status=PROCESSING",
        method: "get",
        withoutLoading: true,
        withoutError: true,
        params,
    });
}
export function depositAccept(data) {
    return request({
        headers: {
            "Content-Type": "application/json",
        },
        url: "/operator/deposit/accept",
        method: "post",
        data: data,
    });
}
export function depositReject(data) {
    return request({
        headers: {
            "Content-Type": "application/json",
        },
        url: "/operator/deposit/reject",
        method: "post",
        data: data,
    });
}