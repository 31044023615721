import React from 'react'
import CustomLink from '../../commons/Link/Link'

const listItem = [
  {
    title: 'Chi phí đầu tư thấp',
    desc: 'Chi phí đầu tư và nhượng quyền hấp dẫn so với đơn vị khác trên thị trường'
  },
  {
    title: 'Thời gian Set Up',
    desc: ' 3-5 ngày kể từ ngày kí kết hợp đồng.'
  },
  {
    title: 'Hệ thống chất lượng',
    desc: 'Video chất lượng cao, các chủ đề đều chọn lọc theo xu hướng, mang tính sáng tạo và chất lượng cao, đa dạng và không ngừng nâng cấp R&D sản phẩm mới.'
  },
  {
    title: 'Hoàn vốn nhanh',
    desc: 'Thời gian hoàn vốn nhanh từ tháng thứ 2 trở đi.'
  }
]
const HomePartner = () => {
  return (
    <div className='partnerHomeWrap'>
      <div className='container'>
        <div className='partnerHome'>
          <div className='homePartnerLeft'>
            <h3 className='heading'>Khi bạn trở thành đối tác</h3>
            <p>Hãy gia nhập hệ thống YTService để nắm bắt cơ hội tốt và cùng chúng tôi làm giàu.</p>
            <CustomLink url='' text='Xem thông tin chi tiết' icon className={'red'} />
          </div>
          <div className='homePartnerRight'>
            {
                            listItem?.map((item, i) => (
                              <div key={i} className='rewardItem'>
                                <div className='pNumber'>
                                  <span>{`0${i + 1}`}</span>
                                  <img src='/images/commons/pointer.svg' alt='icon' />
                                </div>
                                <h4 className='title'>{item.title}</h4>
                                <p className='desc'>{item.desc}</p>
                              </div>
                            ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePartner
