import {
  Home as HomeIcon,
  MailOutline,
  VideoLibrary,
} from "@material-ui/icons";
// pages
import Dashboard from "../pages/dashboard";
import Contact from "../pages/contact/Contact";
import ViewOrder from "../pages/orders/ViewOrder";
import OldViewOrder from "../pages/old-orders/OldViewOrder";
import Deposit from "../pages/deposit/Deposit";

const routes = [
  // {
  //   id: 0,
  //   label: "Dashboard",
  //   link: "/app/dashboard",
  //   icon: <HomeIcon />, 
  //   ContentComponent: Dashboard,
  //   notAuth: true,
  // },
  {
    id: 1,
    label: "Deposit History",
    link: "/app/deposit-history",
    icon: <VideoLibrary />,
    exact: true,
    ContentComponent: Deposit,
    notAuth: true,
  },
  {
    id: 2,
    label: "Liên hệ hỗ trợ",
    link: "/app/support",
    icon: <MailOutline />,
    exact: true,
    ContentComponent: Contact,
    notAuth: true,
  },
];

export default routes