import React from 'react'

const Contact = () => {
  return (
    <div className='contactWrapHome'>
      <div className='container'>
        <div className='contacts'>
          <div className='contactLeft'>
            <h3>
              Khởi nghiệp cùng YTService
              Khởi nghiệp chưa bao giờ là dễ dàng
              nhưng nếu bạn có chiến lược đúng đắn
              bạn có thể dễ dàng đi thật xa !
            </h3>
          </div>
          <div className='contactRight'>
            <h3>Thông tin liên hệ</h3>
            <p>
              Quý khách có nhu cầu làm đối tác, vui lòng điền thông tin vào form  bên dưới .
              Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất để tư vấn.
              Trân trọng cảm ơn !
            </p>
            <form>
              <input type='text' name='name' placeholder='Họ tên của bạn' />
              <input type='text' name='phone' placeholder='Số điện thoại' />
              <input type='text' name='email' placeholder='Email liên hệ' />
              <button type='submit' className='button black'>Gửi thông tin</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
