import React from 'react'

const listBenefit = [
  {
    title: 'Tư vấn Setup hệ thống',
    desc: 'Được hỗ trợ tư vấn setup hệ thống, mua loại thiết bị máy móc theo quy mô đầu tư.',
    icon: '/images/benefit/b1.svg'
  },
  {
    title: 'Hỗ trợ toàn bộ quy trình làm việc',
    desc: 'Các bạn chỉ cần bỏ vốn và kí hợp đồng hợp tác, thành lập công ty. Các khâu tìm kiếm nội dung, sản xuất video và quản trị kênh,.. từ A-Z công ty sẽ thực hiện.Các bạn chỉ cần bỏ vốn và kí hợp đồng hợp tác, thành lập công ty. Các khâu tìm kiếm nội dung, sản xuất video và quản trị kênh,.. từ A-Z công ty sẽ thực hiện.',
    icon: '/images/benefit/b2.svg'
  },
  {
    title: ' Tài sản đầu tư được đảm bảo',
    desc: 'Tất cả tài sản đều được đảm bảo trong quá trình hợp tác như: thiết bị  (máy tính, điều hòa,..), gmail, kênh youtube,… đều thuộc sở hữu của đối tác trong quá trình hợp tác',
    icon: '/images/benefit/b3.svg'
  },
  {
    title: '24/7 Hỗ trợ vận hành',
    desc: 'Trong quá trình vận hành gặp vướng mắc hoặc khó khắn, phía YTService sẽ luôn hỗ trợ đối tác 24/7.',
    icon: '/images/benefit/b4.svg'
  },
  {
    title: 'Doanh thu chia sẻ cao',
    desc: 'Lợi nhuận chia sẻ lên đến tối đa 40%. Với gói đầu tư cao nhất đem đến lợi nhuận chia sẻ tối đa lên đến 40% (lợi nhuận). Ví dụ lợi nhuận sau chi phí là 1.000.000.000 vnd, đối tác sẽ nhận được 400.000.000.',
    icon: '/images/benefit/b5.svg'
  },
  {
    title: 'Hệ thống báo cáo doanh thu',
    desc: 'Nhà đầu tư được cập nhật liên tục trên mobile về doanh thu từ kênh/video đem lại. Đống thời nắm được tình trạng hoạt động của hệ thống.',
    icon: '/images/benefit/b6.svg'
  }
]
const Benefit = () => {
  return (
    <div className='benefitsWrap'>
      <div className='container'>
        <div className='benefits'>
          <h3 className='heading'>Quyền lợi của đối tác</h3>
          <div className='benefitList'>
            {
                            listBenefit?.map((item, i) => (
                              <div key={i} className='benefitItem'>
                                <img src={item.icon} alt={item.title} />
                                <h3>{item.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: item.desc }} className='desc' />
                              </div>
                            ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Benefit
