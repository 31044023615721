import React from 'react'
import CustomLink from '../../commons/Link/Link'

const listService1 = [
  {
    title: 'Tự phân tích từ khóa, nghiên cứu chủ đề.'
  }, {
    title: 'Tự sản xuất content.'
  }, {
    title: 'Tự upload nội dung, quản lí kênh youtube.'
  }, {
    title: 'Tự chạy quảng cáo, quảng bá kênh (tốn chi phí lớn).'
  }, {
    title: ' Khó khăn trong phương thức nhận thanh toán từ google.'
  }, {
    title: 'Nội dung đều gặp vấn đề không có view dẫn đến chi phí cao, khó duy trì'
  },
]
const listService2 = [
  {
    title: ' Kinh nghiệm lâu năm trong phân tích từ khóa và lựa chọn chủ đề'
  },
  {
    title: 'Đội ngũ chuyên nghiệp sản xuất content.'
  },
  {
    title: 'Quản lí kênh youtube, upload nội dung linh hoạt.'
  },
  {
    title: 'Cam kết 100% có view và kiếm được lợi nhuận trên kênh'
  },
  {
    title: 'Thanh toán minh bạch, xử lí nhanh có đối soát đầy đủ cho nhà đầu tư.'
  },
  {
    title: 'Nội dung hấp dẫn, lượng view nhiều, kênh doanh thu cao'
  },
]
const WhyChoseWe = () => {
  return (
    <div className='whyChose'>
      <div className='container'>
        <div className='whyChoseContainer'>
          <div className='whyColumn'>
            <h3 className='title'>Đối tác tự làm Youtube</h3>
            <ul>
              {
                listService1.map(item => <li className='inactive'>{item.title}</li>)
              }
            </ul>
          </div>
          <div className='whyColumn'>
            <h3 className='title'>Khi hợp tác cùng YTService</h3>
            <ul>
              {
                listService2.map(item => <li>{item.title}</li>)
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChoseWe
