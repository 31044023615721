import { Box, Button, Grid, TextareaAutosize } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { CSVLink, CSVDownload } from "react-csv";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
// components
import PageTitle from "../../components/PageTitle/PageTitle";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const types = [
    {
        name: 'Loại yêu cầu',
        value: ''
    },
    {
        name: 'Lỗi hệ thống',
        value: 'loi-he-thong'
    },
    {
        name: 'Lỗi kỹ thuật',
        value: 'loi-ky-thuat'
    },
    {
        name: 'Hoạt động kinh doanh',
        value: 'hd-kinh-doanh'
    },
]
export default function Contact() {
    const classes = useStyles()
    const [contact, setContact] = React.useState({
        err: '',
        type: '',
        title: '',
        message: '',
    });

    const handleChange = (event) => {
        setContact({
            ...contact,
            type: event.target.value
        });
    };
    return (
        <div style={{ background: '#fff' }}>
            <PageTitle color="#000" title="Hỗ trợ liên hệ" />
            <div className="wrap-i" style={{ display: 'flex' }}>
                <div className="ct-heading">
                    Hotline
                </div>
                <div className="ct-hotline">
                    <div className="ct-info">
                        <div className="ct-left">
                            Kinh doanh
                        </div>
                        <div className="ct-right">
                            :0985 111 123
                        </div>
                    </div>
                    <div className="ct-info">
                        <div className="ct-left">
                            Kỹ thuật
                        </div>
                        <div className="ct-right">
                            :0784 123 456
                        </div>
                    </div>
                    <div className="ct-info">
                        <div className="ct-left">
                            Hệ thống
                        </div>
                        <div className="ct-right">
                            :0971 234 432
                        </div>
                    </div>
                </div>
            </div>
            <div className="ct-form">
                <form>
                    <div className="ct-row">
                        <div className="ct-label">Yêu cầu</div>
                        <div className="ct-input">
                            <Select
                                value={contact.type}
                                onChange={handleChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                    types?.map(pk => <MenuItem key={pk.name} value={pk.value}>{pk.name}</MenuItem>)
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="ct-row">
                        <div className="ct-label">Tiêu đề</div>
                        <div className="ct-input">
                            <TextField value={contact.title} onChange={(event) => setContact({ ...contact, title: event.target.value })} />
                        </div>
                    </div>
                    <div className="ct-row">
                        <div className="ct-label">Nội dung</div>
                        <div className="ct-input">
                            <TextareaAutosize value={contact.message} onChange={(event) => setContact({ ...contact, message: event.target.value })} />
                        </div>
                    </div>
                    <div className="ct-row">
                        <div className="ct-label"></div>
                        <div className="ct-input">
                            <Button disabled={!contact.title || !contact.type} type="submit" variant="contained" color="primary">Gửi</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
